import utils from "../../../utils/utils";
import Dropzone from "../../../views/DropZone.vue";
import pageheader from "../../common/pageheader";
import appsheettoolsService from "../../../api/appsheettools.service";
import { mapGetters } from "vuex";
import moment from "moment";
import XLSX from "xlsx";
export default {
  name: 'vendor-service-list-upload',
  components: {Dropzone,pageheader,XLSX},
  props: [],
  data () {
    return {
      input:{},
      ServiceListSearch:"",
      page:0,
      files:[],
      showUpload:false,
      dialog:false,
      isLoading:false,
      ServiceList:[],
      headers:[
        {text: 'S.No', value: 'sno',width: '5px',
        sortable: false},
        {text: 'File Name', value: 'fileName'},
        {text: 'Date', value: 'startDate'},
        {text: 'Status', value: 'status',align: "center"},
        {text: 'Total', value: 'totalRecords',align: "center"},
        {text: 'Valid', value: 'validRecords',align: "center"},
        {text: 'Error', value: 'errorRecords',align: "center"},
        {text: 'Actions', value: 'view',align: 'center',width:"250px",sortable:false}
      ],
      ServiceToolsItems:[],
      ServiceToolHeaders: [
        {text:"S.No", value: 'sno',sortable:"false",align:"center",width:"100px"},
        {text: '', value: 'errorIcon',align: "center"},
        {text: "Error Message", value: "errorMessage",align:"center",width:"150px"},
        {text: "Warning Message", value: "warningMessage",align:"center",width:"180px"},
        {text: "TU Serial", value: "tuSerial",width:"150px"},
        {text: "Brand", value: "brand",width:"180px"},
        {text: "Model", value: "model",width:"130px"},
        {text: "VIN", value: "vin",width:"100px"},
        {text: "Year ", value: "year",width:"100px"},
        {text: "Type", value: "type",width:"112px"},
        {text: "Machine Id", value: "machineId",width:"110px"},
        {text: "Service Type", value: "serviceType",width:"95px"},
        {text: "Ship Date", value: "shipDate",width:"130px"},
        {text: "Installation Date", value: "installationDate",width:"130px"},
        {text: "Device", value: "device",width:"100px"},
        {text: "Engine Hours", value: "engineHours",width:"100px"},
        {text: "Vendor Technician", value: "vendorTechnician",width:"100px"},
        {text: "Vendor Name", value: "vendorName",width:"100px"},
        {text: "Access Control", value: "accessControl",width:"100px"},
        {text: "Notes", value: "notes",width:"100px"}, 
        {text: "Inputs", value: "inputs",width:"100px"},
        {text: "Photos", value: "photos",width:"100px"},
        {text: "Spec Check", value: "specCheck",width:"100px"},
        {text: "CAN Provide", value: "canProvide",width:"100px"}, 
        {text: "PO Number", value: "poNumber",width:"100px"},
        {text: "Customer Id", value: "customerId",width:"100px"},
        {text: "Reference Field", value: "referenceField",width:"100px"},
       ],
       Logsdialog: false,
       viewLogs :[],
       LogsSearch:"",
       loadingLogs:false,
       fileName:"",
       Executedialog:false,
       SelectedTool:"",
       selectedId:"",
       fileLoader:false,
       Id:"",
       GetServiceExcel:[],
       companyToken:"",
    }
  },
  filters: {
    formatDate: function(value) {
      if (value) {
        const format = "MM-DD-YYYY";
        return moment(value).format(format);
      }
    }
  },
  computed: {
    ...mapGetters(["roles" ,"referenceId","name", "customerToken","customerReferenceId","companyName","assignedTools","userId","reportId"]),
  },
  mounted () {
    this.input = utils.getInputContent("serviceListUpload", this.roles);
    this.GetServiceList();
    this.Id = this.companyName;
    this.companyToken = this.$store.getters.customerToken;
    this.customerId = this.$store.getters.referenceId;
  },
  methods: {
    openUploadDialog(){
      this.files = [];
      this.dialog = true;
      this.showUpload = false;
    },
    closeUploadDialog(){
      this.$refs.dropzone.removeAllFiles();
      this.dialog = false;
      this.showUpload = false;
    },
    dropzoneS(file) {
      utils.fileToBase64(file).then((base64String) => {
        let fileData = {
          fileName: file.name,
          fileContent: base64String.split(",")[1],
        };
        this.files.push(fileData);
        this.showUpload = true;
      });
    },
    dropzoneR(file) {
      utils.fileToBase64(file).then((base64String) => {
        let filterFiles = this.files.filter(function(a) {
          return a.fileName != file.name && a.fileContent != base64String;
        });
        this.files = filterFiles;
      });
    },
    removeFiles() {
      this.$refs.dropzone.removeAllFiles();
    },
    async UploadFile(){
      let payload = {
        userId: this.userId,
        fileInfo: this.files[0],
      }
      if(this.files[0] == null){
        this.$toast.error("Please select a file ");
      }
      else{
        try{
          this.selecteditem = this.selectedTitle;
        this.fileLoader = true;
        let apiResult = await appsheettoolsService.ServiceFileUploadVendor(this.reportId,payload);
        this.dialog = false;
        if (apiResult.isSuccess == true) {
          this.fileLoader = false;
          this.$refs.dropzone.removeAllFiles();
          this.$toast.success(apiResult.systemMessage);
          this.$toast.success("File Uploaded Successfully");
          this.GetServiceList();
        }
        else{
          this.$toast.error(apiResult.systemMessage);
          this.GetServiceList();
      }
      }
      catch (err) {
       this.fileLoader = false;
        this.closeUploadDialog();
        this.$toast.error("Unable to upload file" + err);
      }
      }
    },
    async GetServiceList(){
      this.isLoading = true;
      try{
        let response = await appsheettoolsService.VendorServiceList(this.reportId);
        if (response) {
          this.isLoading = false;
          this.ServiceList = [];
          this.ServiceList = response.map((d, index) => ({
            ...d,
            sno: index + 1,
            index: false
          }));
        }
      }
      catch(err){
        this.$toast.error("Unable to load the List" + err);
        this.isLoading = false;
      }
    },
    getColor (status) {
      if (status == "Completed") return 'green'
      else if (status == "LogsCreated") return 'blue'
      else if (status == "ExecutionInProgress") return '#013220'
      else if (status == "InProgress") return 'orange'
      else if (status == "Failed") return 'red'
      else return 'black'
    },
    closepopup(){
      this.ServiceToolsItems = [];
      this.Logsdialog = false;
    },
    async ShowLogs(item){
      this.fileName = item.fileName;
      let toolId = item.toolId;
      this.Logsdialog = true;
      try{
          this.loadingLogs = true;
          let response = await appsheettoolsService.GetViewServiceLogsVendor(toolId,this.reportId);
          if (response) {
            this.loadingLogs = false;
          this.ServiceToolsItems = response.map((d, index) => ({
            ...d,
            sno: index + 1,
          }));
          this.ServiceExcelResponse = response;
        }
      }
        catch(err){
          this.$toast.error("Unable to Execute" + err);
          this.loadingLogs = false;
        }
      },
      showExecuteDialog(item,index)
      {
        this.SelectedTool = item;
        this.selectedId = index;
        this.Executedialog = true;
      },
      async ShowExecutedLogs(id,index){
        console.log(index);
        var t = this;
        let payload = {
          customerId: this.reportId,
          userId: this.userId,
          toolId: id
        }
        this.Executedialog = false;
        try{
          let response = await appsheettoolsService.ExecuteServiceToolsVendor(payload);
          if (response.isSuccess == true) {
            this.$toast.success("Execution in progress!!");
            t.ServiceList[index-1]["index"] = true;
            this.Executedialog = false;
          }
          else{
            t.ServiceList[index-1]["index"] = false;
            this.Executedialog = false;
            this.$toast.error(response.systemMessage);
          }
        
        } catch (err) {
          this.Executedialog = false;
          this.$toast.error("Unable to Execute" + err);
          this.loading = false;
        }
      },
      LoadExcel(){
          this.GetServiceExcel = [];
          this.GetServiceExcel.push(["Service List Upload Logs"]);
          this.GetServiceExcel.push([]);
          this.GetServiceExcel.push([
            "TU Serial",
            "Brand",
            "Model",
            "VIN",
            "Year",
            "Type",
            "Machine Id",
            "Service Type",
            "Ship Date",
            "Installation Date",
            "Device",
            "Engine Hours",
            "Vendor Technician",
            "Vendor Name",
            "Access Control",
            "Notes",
            "Inputs",
            "Photos",
            "Spec Check",
            "CAN Provide",
            "PO Number",
            "Customer Id",
            "Reference Field",
            "Warning Message",
            "Error Message"
          ]);
          var ws1 = XLSX.utils.aoa_to_sheet(this.GetServiceExcel);
          let exceldata = this.ServiceExcelResponse.map(function(item) { 
            delete item.installationDateTime;
            delete item.serviceTypeFk;
            delete item.trackunitDeviceFk;
            delete item.vendorTechnicianFk;
            delete item.vendorNameFk;
            delete item.customerId;
            delete item.id;
            delete item.customerFk;
            delete item.assetFk;
            delete item.installerNotes;
            return item; 
          });
          XLSX.utils.sheet_add_json(ws1, exceldata, {
            header: [
              "tuSerial",
              "brand",
              "model",
              "vin",
              "year",
              "type",
              "machineId",
              "serviceType",
              "shipDate",
              "installationDate",
              "device",
              "engineHours",
              "vendorTechnician",
              "vendorName",
              "accessControl",
              "notes",
              "inputs",
              "photos",
              "specCheck",
              "canProvide",
              "poNumber",
              "customerId",
              "referenceField",
              "warningMessage",
              "errorMessage"
            ],
            skipHeader: true,
            origin: "A4",
          });
          var wb1 = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb1, ws1, "Service List  Logs");
           this.GetServiceExcel = [];
           XLSX.writeFile(wb1, "Service List  Logs.xlsx");
        }
        }
}


